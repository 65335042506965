footer {
    background-color: #476ac1;
    color: #fff;
    text-align: center;
    padding: 1rem;
    width: 100%;
}

.footer .logo img {
    height: 40px; /* Adjusts the logo size in the footer */
}

/* Footer navigation similar to header, adjust if needed */
.footer .navigation ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
}

.footer .navigation ul li {
    margin-left: 20px;
}

.footer .navigation ul li a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
}

.footer .navigation ul li a img {
    height: 40px; /* Adjusts the size of icons in the footer */
    margin-right: 8px;
}