.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header .logo img {
    height: 50px; /* Adjust size accordingly */
}

.navigation ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
}

.navigation ul li {
    margin-left: 20px;
}

.navigation ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.navigation ul li a img {
    height: 24px; /* Adjust size to fit your design */
    margin-right: 8px; /* Space between icon and text (if any) */
}

.menu-icon {
    display: none;
    cursor: pointer;
    font-size: 24px; /* Adjust the size to fit the design */
    background: none;
    border: none;
    color: #333; /* Change the color to match the text or theme */
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .menu-icon {
        display: block;
    }

    .navigation {
        display: none;
        width: 100%;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
        max-height: 0; /* Start with max-height 0 */
        background-color: #fff; /* Optional: Set a background color */
    }

    .navigation.open {
        max-height: 500px; /* Adjust based on content */
        display: flex; /* Ensure this stays as flex to align items properly */
    }

    .navigation ul {
        flex-direction: column;
        width: 100%;
        padding-top: 10px; /* Add padding to top when menu is open */
    }

    .navigation ul li {
        margin: 10px 0; /* Increased margin for touch targets */
        width: 100%; /* Ensure full width for easier tap targets */
        text-align: center; /* Center align text */
    }

    .navigation ul li a {
        width: 100%; /* Allows the link to fill the li for easier tapping */
        display: block; /* Makes the anchor fill the li */
    }
}