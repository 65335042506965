@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    overflow-x: hidden;
    color: #333;
    font-family: 'Space Mono', monospace;
}

.body-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 1rem;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

main {
    flex: 1;
    padding: 20px 40px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.tech-badge-container {
    margin-top: 60px;
    padding: 20px 0;
}

.github-contribution-chart {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 100%;
    height: auto;
}

.back-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 30px;
    color: #333;
    padding: 0;
    font-family: 'Space Mono', monospace;

}

.post-title {
    margin: 0;
    font-weight: 700;
}

.title-container {
    display: flex;
    alignItems: center;
    marginBottom: 10px;
    marginLeft: -10px; /* Adjust as necessary */
}